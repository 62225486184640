
body {
    padding:0;
    margin:0;
    font-family: 'Roboto', sans-serif;
}

.font_righteous {
    font-family: 'Righteous', cursive;
}

.bck_black {
    background: #3c3c3c;
}
.bck_white {
    background: #ffffff;
}
.bck_red {
    background: #cc3300;
}
.bck_lightred {
    background: #ff794d;
}
.bck_yellow {
    background: #ffa800;
}
.bck_grey {
    background: #acaaa8;
}

.bck_orangegrey {
    background: #e67300;
}

.bck_bluegradient {
    background: #c2e59c;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #64b3f4, #c2e59c);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #64b3f4, #c2e59c); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}

.bck_bluegreen {
    background: #66cc99;

}

.iconImage {
    width: 20px;
    margin-right: 10px;
}

.center_wrapper {
    width: 900px;
    margin:0 auto;
    font-size: 24px;
    
    font-family: 'Arimo', sans-serif;

}
.titles{


    font-family: 'Arimo', sans-serif;
    text-align: center;
    text-transform: uppercase;
    color:#2c2c2c;
    font-size: 52px;
 
}
/*====================
        GeneralInfo
======================*/
.flex-container {
    display: flex;
    height: 100px;
    justify-content: center;
    align-items: center;
  
   
    margin-top: 30px;
    margin-bottom: 150px;
    margin-left: 250px;
    margin-right: 250px;
    font-size: 130%
  }

  .flex-container-list{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin-top: 40px;
    margin-left: 550px;
    margin-right: 250px;
    margin-bottom: 50px;
    font-size: 130%
  }
  .GeneralInfo{
    align-items: center;
    justify-content: center;
  }
/*====================
        HEADER
======================*/


header {
    padding:10px 0px;
    transition: all 300ms ease-in;
}

header .header_logo {
    flex-grow: 1;
}
header .header_logo_venue {
    font-size: 40px;
    text-align: center;
}

header .header_logo_title {
    text-transform: uppercase;
    text-align: center;
}

.spaceTop {
    margin-top: 180px;
    text-align: center;
}

/*====================
        About
======================*/
.About{ text-align: center;
    display: flex;
    display: block;}

/*====================
        FOOTER
======================*/

footer {
    padding:50px 0px;
    text-align: center;
    font-size: 60px;
    color:#ffffff;
    background: rgb(167, 162, 162);
}

footer .footer_copyright {
    font-size: 18px;
}

/*====================
        CARROUSEL
======================*/


.carrousel_wrapper {
    height:700px;
    overflow: hidden;

}
.carrousel_wrapper_attractions {
    height:700px;
    overflow: hidden;
    margin-left: 300px;
    margin-right: 300px;
    margin-top: 70px;
    margin-bottom: 200px;
}

.carrousel_image {
    
    background-size: cover !important;
}

.artist_name {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 400px;
    height: 160px;
    -webkit-transform: translate(-50%, -50%);  
    transform: translate(-50%, -50%);   
    border: 2px solid white;
}

.artist_name .wrapper {
    border: 2px solid white;
    height: 121px;
    margin: 6px;
    text-align: center;
    color: #fff;
    font-size: 40px;
    padding: 21px 70px 0px 70px;
    text-transform: uppercase;
}

/*====================
        COUNTDOWN
======================*/

.countdown_wrapper {
    position:absolute;
    bottom: 0px;
    color:#ffffff;
}

.countdown_wrapper .countdown_top {
    background: #ff4800;
    font-size: 19px;
    padding: 10px;
    display: inline-block;
    text-transform: uppercase;
}

.countdown_wrapper .countdown_bottom {
    display: flex;
    background: #d93d00;
    padding: 10px 20px;
}

.countdown_wrapper .countdown_time {
    font-size: 70px;
    border-right: 1px solid #F44336;
    margin-right: 14px;
    padding-right: 49px;
}
.countdown_wrapper .countdown_item:last-child .countdown_time{
    border:none;
}

.countdown_wrapper .countdown_tag {
    text-transform: uppercase;
    font-size: 20px;
    padding-left: 7px;
}

/*====================
        VENUE NFO
======================*/

.vn_wrapper {
    display: flex;
    padding: 80px 0px;
}

.vn_item {
    width:100%;
    color:#4e4b4b;
    text-align: center;
}
                      
.vn_item .vn_outer {
    width: 300px;
    border: 2px solid #828282;
    margin: auto;
    padding: 5px;
}

.vn_item .vn_inner { 
    border: 2px solid #828282;
    position: relative;
    padding: 0px 20px;
    transition: all 500ms ease;
}

.vn_item .vn_inner:hover {
    background: #d3d1d1;
}

.vn_item .vn_icon_square {
    width: 90px;
    height: 90px;
    position: absolute;
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    top: -50px;
    left: 105px;
}

.vn_item .vn_icon {
    width: 50px;
    height: 50px;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    position: absolute;
    top: -30px;
    left: 124px;
}

.vn_item .vn_title {
    font-size: 22px;
    margin: 70px 0px 20px 0px;
    border-bottom: 1px solid #5d5d5d;
    padding-bottom: 10px;
}

.vn_item .vn_desc {
    font-size: 20px;
    font-weight: 300;
    margin-bottom: 90px;
}

/*====================
        HIGHLIGHTS
======================*/


.highlight_wrapper h2 {
    text-align: center;
    text-transform: uppercase;
    color:#2c2c2c;
    font-size: 52px;
    justify-content: center;
    align-items: center;
}

.highlight_wrapper .highlight_description {
    line-height: 30px;
    font-size: 18px;
    font-weight: 300;
    border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
    padding: 30px 0px;
    justify-content: center;
    align-items: center;
}

.discount_wrapper {
    display: flex;
    padding: 70px 0px;
    font-size: 18px;
}

.discount_wrapper .discount_porcentage {
    padding-right: 100px;
}

.discount_wrapper .discount_porcentage span:nth-child(1) {
    color: #ff4800;
    font-size: 125px;
    font-weight: 400;
    display: block;
    line-height: 120px;
}

.discount_wrapper .discount_porcentage span:nth-child(2) {
    color: #2c2c2c;
    font-size: 50px;
    font-weight: 400;
    display: block;
}


.discount_wrapper .discount_description h3 {
    font-size: 34px;
    font-weight: 100;
    margin: 0px;
    color: #2c2c2c;
}

.discount_wrapper .discount_description p { 
    line-height: 30px;
    font-size: 18px;
    font-weight: 300;
}

/*====================
        PRICING
======================*/

.pricing_section {
    padding: 30px 0px;
    margin-bottom: 70px;
}


.pricing_section h2 {
    color: #ffffff;
    text-transform: uppercase;
    text-align: center;
    font-size: 50px;
    margin: 0;
}

.pricing_wrapper {
    display: flex;
    flex-wrap: wrap;
}
.pricing_wrapper .pricing_item{
    flex-grow: 1;
    width: 33%;
    padding: 20px;
    box-sizing: border-box;
}

.pricing_wrapper .pricing_inner_wrapper {
    border:2px solid #ffa800;
    padding: 50px 20px;
}

.pricing_inner_wrapper .pricing_title {
    color: #ffffff;
    text-align: center;
    border-bottom: 1px solid #ffa800;
    padding-bottom: 20px;
}
.pricing_inner_wrapper .pricing_title span:nth-child(1) {
    font-size: 50px;
    display: block;
}
.pricing_inner_wrapper .pricing_title span:nth-child(2) {
    text-transform: uppercase;
    font-size: 28px;
    font-weight: 300;
}
.pricing_inner_wrapper .pricing_description {
    color: #b8b8b8;
    font-weight: 300;
    font-size: 14px;
    text-align: center;
    padding: 20px 0px;
    min-height: 70px;
}

.pricing_inner_wrapper .pricing_buttons {
     text-align: center;
}


/*====================
        LOCATION    
======================*/

.location_wrapper {
    position:relative;
}

.location_tag {
    position: absolute;
    bottom: 0px;
    width: 100%;
}
.location_tag div{
    background: #2c2c2c;
    color: #ffffff;
    text-transform: uppercase;
    width: 220px;
    margin: 0 auto;
    font-size: 29px;
    padding: 15px 20px;
    text-align: center;
}
